import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"

const ArtCenter = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { artCenterCloudinary } = useStaticQuery(graphql`
    query {
      artCenterCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/artcenter/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>04. ArtCenter Library</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Identity, Exhibition</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-artcenter">
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/sueños">Previous Project(03) ↖</Link>
                    </h2>
                    <h2>
                      <Link to="/projects/pueblo-sonidero">
                        Next Project(05) ↗
                      </Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>ArtCenter Library </p>
            </div>
            <div className="col-lg-7">
              <p>
                Many of the forms are inspired by objects from within and around
                the library space, which include the architecture of ArtCenter,
                books, book spines, library shelves, and other environmental
                forms that help not only create but also inform, complete, and
                define the feeling of this space as a whole.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[1].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[12].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <div className="embed-responsive embed-responsive-1by1">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607106540/rgrq/static/artcenter/14_ty0gii.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[13].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[14].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[15].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[16].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[17].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[18].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-8 m-d-60">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[19].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[20].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[21].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[22].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[23].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[24].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[25].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[26].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[27].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[28].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[29].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[30].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[31].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[32].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[33].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-8 m-d-60">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[34].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[35].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[36].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[37].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[38].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[39].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[40].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[41].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[42].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[43].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[44].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[45].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[46].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[47].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[48].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[49].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[50].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[51].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[52].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[53].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[54].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[55].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[56].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[57].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[58].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[59].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[60].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[61].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[62].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[63].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[64].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[65].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-8">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[66].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[67].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[68].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[69].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[70].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[71].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[72].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[73].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[74].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[75].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[76].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[77].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[78].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[79].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[80].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[81].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[82].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[83].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[84].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[85].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[86].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[87].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[88].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[89].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-3">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[90].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[91].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[92].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[93].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[94].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[95].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={artCenterCloudinary.edges[96].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Credits</p>
            </div>
            <div className="col-lg-7">
              <p>01.–24. Photography by Maribel Barcena Lopez</p>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Rolando Castillo</p>
              <p>Maria Trakovsky</p>
              <p>Ozzy Alvarez</p>
              <p>Jennifer Faist </p>
              <p>Alfonso Huerta </p>
              <p>Robert Lundquist</p>
              <p>Mario Ascencio</p>
              <p>Rachel Julius</p>
              <p>Joseph Abernethy</p>
              <p>Gabriel Pulgar</p>
              <p>Ramon Munoz</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default ArtCenter
